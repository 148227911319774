import React, {useEffect} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../context';
import {Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, Col} from 'react-bootstrap';
import Button from '../../Button';
import Icon from '../../../../assets/icons';
import Header from '../../Header';
import TableHeader from '../../TableContainer/TableHeader';
import {BASE_URL, dateFilterMapping, DEVICE_HEIGHT, formateIdForApi, formatNum, handleDashboardHeader} from '../../../../constants';
import ListItem from './ListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../Loading';
import API_ENDPOINTS from '../../../../api/endpoints';
import Cookies from 'js-cookie';
import PaginationLoader from '../../PaginationLoader';

const ViewDataModal = ({toggle, setToggle, allListData, selectedLocation, selectedDateRange, hasMore, setCurrentPage, setHasMore, totalpages, title, setAllListData, loading}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business} = useAuthContext();
    const locationString = formateIdForApi(selectedLocation, business?.id);
    /**
     * Handles the closing of the modal by resetting the list data and toggle state.
     */
    function handleClose(event) {
        setToggle(false);
        setAllListData({name: '', data: []});
        setCurrentPage(0);
    }
    const handleback = () => {
        setToggle(false);
        // setText();
    };

    const handleExportCSV = async val => {
        let dataForapi = {};

        if (val === 'top_selling_items' || val === 'low_selling_items') {
            dataForapi = {
                start_date: selectedDateRange?.start_date,
                end_date: selectedDateRange?.end_date,
                date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
                business_id: locationString,
                topSellingItems: val === 'top_selling_items',
                endPoint: API_ENDPOINTS?.GET_TOP_AND_LOW_SELLING_PRODUCTS_EXPORT_CSV,
            };
        } else if (val === 'low_stock_items' || val === 'out_of_stock_items') {
            dataForapi = {
                business_id: locationString,
                is_lowstock: val === 'low_stock_items',
                is_export: true,
                endPoint: API_ENDPOINTS?.GET_LOW_AND_OUT_OF_STOCK_PRODUCTS_And_EXORT_CSV,
            };
        } else if (val === 'location_wise_sales') {
            dataForapi = {
                business_id: locationString,
                is_export: true,
                start_date: selectedDateRange?.start_date,
                end_date: selectedDateRange?.end_date,
                date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
                endPoint: API_ENDPOINTS?.GET_LOCTION_WISE_SALES_AND_EXPORT_CSV,
            };
        }
        try {
            const url = new URL(`${BASE_URL}${dataForapi.endPoint}`);
            val === 'low_stock_items' || val === 'out_of_stock_items'
                ? url.searchParams.append('business_id', dataForapi.business_id)
                : url.searchParams.append('business_ids', dataForapi.business_id);
            dataForapi?.start_date && url.searchParams.append('start_date', dataForapi.start_date);
            dataForapi?.end_date && url.searchParams.append('end_date', dataForapi.end_date);
            dataForapi?.date_range && url.searchParams.append('date_range', dataForapi.date_range);

            (val === 'top_selling_items' || val === 'low_selling_items') && url.searchParams.append('topSellingItems', dataForapi.topSellingItems);
            (val === 'low_stock_items' || val === 'out_of_stock_items') && url.searchParams.append('is_lowstock', dataForapi.is_lowstock);
            dataForapi?.is_export && url.searchParams.append('export', dataForapi.is_export);

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: `Token ${Cookies.get('access_token')}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response?.status === 200) {
                const blob = await response.blob();
                const urlObject = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = urlObject;
                link.download = 'report.csv';
                document.body.appendChild(link);
                link.click();
                link.remove();
                URL.revokeObjectURL(urlObject);
            } else {
                let jsonRes = await response?.json();
                throw new Error(`HTTP error! status: ${response.status}, message: ${jsonRes.message}`);
            }
        } catch (error) {
            console.log('Error downloading the Excel file:', error);
        }
    };

    const colume_count =
        allListData?.name == 'top_selling_items' || allListData?.name == 'low_selling_items'
            ? 'eightColumn'
            : title === 'low_stock_items'
            ? 'fourColumn'
            : allListData?.name == 'out_of_stock_items'
            ? 'fiveColumn'
            : 'sixColumn';

    const headers = handleDashboardHeader(allListData?.name, I18n);

    const renderList = allListData?.data?.map(item => {
        if (title === 'top_selling_items' || title === 'low_selling_items') {
            return {
                col1: item.item_name,
                col2: item.total_quantity,
                col3: `Rs. ${formatNum(item.unit_cost || item.cost_price)}`,
                col4: `${item.margin_percent}%`,
                col5: `Rs. ${formatNum(item.sale_price)}`,
                col6: `Rs. ${formatNum(item.total_sales)}`,
                col7: `${item.unit_cost_percent}%`,
                col8: `${item.contribution}%`,
            };
        } else if (title === 'low_stock_items') {
            return {
                col1: item.item_name,
                col2: item.current_stock,
                col3: item.low_stock_level,
                col4: item.stock_needed,
            };
        } else if (title === 'out_of_stock_items') {
            return {
                col1: item.item_name,
                col2: item.category,
                col3: item.current_stock,
                col4: item.low_stock_level,
                col5: item.stock_needed,
            };
        } else if (title === 'location_wise_sales') {
            return {
                col1: item.business_name,
                col2: item.net_sales,
                col3: item.orders,
                col4: `Rs. ${formatNum(item.tax_collected)}`,
                col5: `Rs. ${formatNum(item.gross_sales)}`,
                col6: `Rs. ${formatNum(item.average_sale)}`,
            };
        }
    });

    /**
     * Fetches more data if there is more data to fetch.
     */
    const fetchMoreData = () => {
        if (hasMore) {
            setCurrentPage(prevPage => {
                const nextPage = prevPage + 1;
                if (nextPage <= totalpages) {
                    return nextPage;
                } else {
                    setHasMore(false);
                    return prevPage;
                }
            });
        }
    };

    return (
        <Modal dialogClassName={'animate-bottom'} className="viewDataModal" onHide={() => handleClose()} keyboard={false} show={toggle} centered scrollable={true}>
            <ModalHeader>
                <div className="modalHeaderLeftSide">
                    <span onClick={() => handleClose()} className="cursorPointer moddalheaderleftIconBox">
                        <Icon name="backArrowIcon" fill={theme.barclaysBlue} height="18" width="18" viewBox="0 0 18 18" />
                    </span>
                    <span className="marBot0 fontSize18  fontWeight400 cursorPointer" style={{color: theme.white}} onClick={() => handleClose()}>
                        {I18n.back}
                    </span>
                </div>
            </ModalHeader>

            <ModalBody>
                <div className="ViewDataBox" style={{backgroundColor: theme.blackBg}}>
                    <Header
                        type="search"
                        hideSeach={true}
                        title={I18n[allListData?.name]}
                        className={'borderRadiusTopleftTopRigt'}
                        rightCta={{
                            title: I18n.export_csv,
                            action: () => handleExportCSV(allListData?.name),
                        }}
                    />
                    <Container fluid>
                        {allListData?.data?.length > 0 ? (
                            <Row className="justify-content-md-center">
                                <Col md={12}>
                                    <TableHeader className={colume_count} style={{borderColor: theme.inputBorder}}>
                                        {headers?.map((header, idx) => (
                                            <div key={idx} className={`BoxWidth ${idx === 0 ? 'justifyStart' : idx === headers.length - 1 ? 'justifyEnd' : 'justifyCenter'}`}>
                                                <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                                    {header}
                                                </p>
                                            </div>
                                        ))}
                                    </TableHeader>
                                    <div
                                        id="scrollableDiv"
                                        className="selectCustomerListBox tableListMainBox"
                                        style={{
                                            height: DEVICE_HEIGHT - 240,
                                        }}>
                                        {renderList.length > 0 ? (
                                            <InfiniteScroll
                                                className="infiniteScrollBar"
                                                dataLength={renderList.length}
                                                next={fetchMoreData}
                                                hasMore={hasMore}
                                                loader={<PaginationLoader />}
                                                scrollableTarget="scrollableDiv">
                                                {renderList.length > 0 && renderList?.map((val, index) => <ListItem colume_count={colume_count} data={val} key={index} />)}
                                            </InfiniteScroll>
                                        ) : (
                                            <Loading />
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Loading />
                        )}
                    </Container>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ViewDataModal;
