import { useLanguageContext, useThemeContext } from "../../../context";
import DropdownRadioList from "./DropDownRadioList";
import DropdownInputList from "./DropdownInputList";
import { Dropdown } from "react-bootstrap";
import DropDownCheckbox from "./DropDownCheckbox";
import DropdownList from "./DropDownList";
import Icon from "../../../assets/icons";
import { useState } from "react";
import Button from "../Button";

const DropdownItem = ({ type, dropDown, option }) => {
  const { ctaDisabled, subTitle, onApply, title, name } = dropDown || {};

  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();

  const [selected, setSelected] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (toggle, event) => {
    if (event.source == 'select' && onApply) return;
    setIsOpen(toggle);
  };

  const handleApply = () => {
    onApply(selected);
    setIsOpen(false);
  };

  return (
    <Dropdown className="customDropDown" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {title && <span className="marRight5 marLeft5">{I18n[title] || title}</span>}
        {subTitle && <span className="marRight5 marLeft5">{I18n[subTitle] || subTitle}</span>}
        {name && <span className="marLeft5 marRight5"><Icon {...dropDown} /></span>}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {dropDown.heading &&
        <p className="marBot15 marTop3 fontSize12 fontWeight600" style={{ color: theme.white}}>
          {I18n[dropDown.heading]}
        </p>
        }
        {type == "radioList" ? (
          <DropdownRadioList dropDown={dropDown} />
        ) : type == "checkBox" ? (
          <DropDownCheckbox dropDown={dropDown} />
        ) : type == "radioInput" ? (
          <DropdownInputList dropDown={dropDown} selected={selected} setSelected={setSelected} />
        ) : (
          <DropdownList dropDown={dropDown} option={option} />
        )}
        {onApply ?
          <Button
            backgroundColor={theme.brightGreen}
            borderColor={theme.brightGreen}
            handleClick={handleApply}
            textColor={theme.white}
            type={"primaryButton"}
            disabled={ctaDisabled}
            title={'Apply'} />
          : null
        }
      </Dropdown.Menu>
    </Dropdown>
  )
};

export default DropdownItem;


