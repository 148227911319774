import React, {useEffect, useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import TextField from '../TextField';
import RangePickerCalendar from '../RangePickerCalendar';
import NewDropDown from '../NewDropDown';
import {formatDateWithTime, PaymentDropDown, salesDropDown, salesReportDropDown} from '../../../constants';

const DashboardHeader = ({
    setSelectedDateRange,
    setDropDownAction,
    dropDownAction,
    selectedLocation,
    setSelectedLocation,
    reportDropDownOption,
    setReportDropDownOption,
    type = 'sales_dashboard',
}) => {
    const {business, userBusiness, role, account} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [locationOption, setLocationOption] = useState([]);

    const onFilterDate = (label = 'Today', startDateStr = '', endDateStr = '', diffInDays = 0) => {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        if (!isNaN(startDate) && !isNaN(endDate)) {
            setSelectedDateRange({
                start_date: formatDateWithTime(startDate),
                end_date: formatDateWithTime(endDate),
                label: label,
            });
        }
    };

    const fetchAllBusiness = async () => {
        const fetchedLocations = [];
        if (role[0]?._raw?.name === 'dashboard-admin') {
            try {
                let allAccount = await account.business.fetch();
                allAccount.forEach(item => {
                    fetchedLocations.push({
                        business_name: item.location_name,
                        id: item.id,
                    });
                });
            } catch (error) {
                console.error('Error fetching business data:', error);
            }
        } else {
            fetchedLocations.push(
                ...userBusiness?.map(item => ({
                    business_name: item.location_name,
                    id: item.id,
                })),
            );
        }
        fetchedLocations.unshift({
            business_name: I18n.all_location,
            id: 'all',
        });

        setLocationOption(fetchedLocations);
    };

    useEffect(() => {
        fetchAllBusiness();
    }, []);

    const onChange = (label, value) => {
        if (label === I18n.sales || label === I18n.payment) setDropDownAction({value: {name: value?.name?.toLowerCase()}});
        else if (label === I18n.reports) {
            setReportDropDownOption({name: value?.name?.toLowerCase(), value: value.value});
        }
    };

    return (
        <div className="dashboardFilterBox">
            <div className="dashboardFilterBoxLeft">
                <div className="dahboardViewBox">
                    <TextField
                        label={type == 'payment_dashboard' ? I18n.payment : I18n.sales}
                        placeholder={type == 'payment_dashboard' ? I18n.payment : I18n.sales}
                        data={type == 'payment_dashboard' ? PaymentDropDown : salesDropDown}
                        value={dropDownAction?.value?.name}
                        onChange={onChange}
                        inputType="select"
                        type="text"
                        suffix="downIcon2"
                        suffix_fill={theme.white}
                        suffix_width={'24'}
                        suffix_height={'24'}
                        suffix_viewBox={'0 0 18 18'}
                    />
                </div>
                {dropDownAction?.value?.name == 'reports' && (
                    <div className="dahboardViewBox">
                        <TextField
                            label={I18n.reports}
                            placeholder={I18n.reports}
                            data={salesReportDropDown}
                            value={reportDropDownOption?.name}
                            onChange={onChange}
                            inputType="select"
                            type="text"
                            suffix="downIcon2"
                            suffix_fill={theme.white}
                            suffix_width={'24'}
                            suffix_height={'24'}
                            suffix_viewBox={'0 0 18 18'}
                        />
                    </div>
                )}
            </div>
            <div className="dashboardFilterBoxRight">
                <NewDropDown
                    iconName="locationIcon"
                    filterOption={locationOption}
                    setSelectedLocation={setSelectedLocation}
                    selectedLocation={selectedLocation}
                    title={I18n.location}
                />
                <RangePickerCalendar onFilterDate={onFilterDate} />
            </div>
        </div>
    );
};

export default DashboardHeader;
