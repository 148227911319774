import {observePaginatedPayments, observePaymentsByBusiness} from '../../../../../../pos-core/database/helpers';
import {DEVICE_HEIGHT, ITEMS_PER_PAGE, formatNum} from '../../../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import TotalTiles from '../../../../../common/TotalTiles';
import {Col, Container, Row} from 'react-bootstrap';
import withObservables from '@nozbe/with-observables';
import {usePayments} from '../../../../../../hooks';
import Pagination from '@mui/material/Pagination';
import {Header} from '../../../../../common';
import React, {useState} from 'react';
import FilterItem from './FilterItem';
import HeaderItem from './HeaderItem';
import ListItem from './ListItem';

let HEADER_DATA = [
    {name: 'amount', className: 'justifyStart'},
    {name: 'date_and_time', className: 'justifyCenter'},
    {name: 'location', className: 'justifyCenter'},
    {name: 'payment_method', className: 'justifyCenter'},
    {name: 'customer_id', className: 'justifyCenter'},
    {name: 'transaction_id', className: 'justifyEnd'},
];

const TransactionList = ({allPayments, payments}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {
        getSortedPayments,
        getPagePayments,
        totalCompleted,
        totalCompTrans,
        getSearchItem,
        totalPenTrans,
        totalRefTrans,
        totalPending,
        filteredList,
        totalRefund,
        totalTrans,
        active,
        total,
    } = usePayments(allPayments, payments);

    const [pagination, setPaginnation] = useState({
        total: Math.ceil(allPayments.length / ITEMS_PER_PAGE),
        current: 0,
    });

    const OVERVIEW_DATA = [
        {
            subTitle: `Transaction ${formatNum(totalTrans)}`,
            iconBGcolor: theme.iconBlue,
            iconName: 'tileAllIcon',
            viewBox: '0 0 14 17',
            width: '14',
            height: '17',
            label: 'total_sales',
            heading: 'all',
            amount: total,
            percent: 0,
            rs: true,
        },
        {
            subTitle: `Transaction ${formatNum(totalCompTrans)}`,
            iconBGcolor: theme.iconGreen,
            iconName: 'succeedIcon',
            viewBox: '0 0 13 10',
            width: '13',
            height: '10',
            amount: totalCompleted,
            label: 'total_orders',
            heading: 'succeeded',
            percent: 0,
            rs: true,
        },
        {
            subTitle: `Transaction ${formatNum(totalPenTrans)}`,
            iconBGcolor: theme.mustardYellow,
            iconName: 'tilePendingIcon',
            label: 'hold_orders',
            amount: totalPending,
            heading: 'pending',
            percent: 0,
            rs: true,
        },        
    ];

    const handlePdf = () => {};

    const onChange = (label, value) => getSearchItem(value);

    const handlePagination = event => {
        const current = event?.target?.innerText - 1 || 0;
        setPaginnation({...pagination, current});

        getPagePayments(current, ITEMS_PER_PAGE);
    };

    return (
        <section className="layoutMainBox" style={{backgroundColor: theme.topBarBG}}>
            <div className="layoutMainInnerBox">
                <Header
                    type="search"
                    title={'Transactions'}
                    search={{
                        placeholder: I18n.search_by_name_and_transactin_id,
                        onChange,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                    }}
                    rightCta={{
                        title: I18n.export_pdf,
                        action: handlePdf,
                    }}
                />
                <div className="topCardsBox transactionTopcard">
                    <Container fluid>
                        <Row>
                            {OVERVIEW_DATA.map((item, index) => {
                                return (
                                    <Col key={index} md={4} xs={12}>
                                        <TotalTiles percent={item.percent} amount={item.amount} item={item} />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </div>
                <Container fluid>
                    <Row>
                        <div className="walletFilterBox">
                            <FilterItem getSortedPayments={getSortedPayments} />
                        </div>

                        <HeaderItem data={HEADER_DATA} />
                        <Col md={12} xs={12}>
                            <div style={{height: DEVICE_HEIGHT - 450, overflow: 'auto'}} className="tableListMainBox">
                                {(active ? filteredList : payments).map((item, index) => (
                                    <ListItem index={index} item={item} />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* {pagination.total <= 1 || active ? null : <Pagination onChange={handlePagination} count={pagination.total} shape="rounded" />} */}
            </div>
        </section>
    );
};

const enhance = withObservables([''], ({}) => ({
    payments: observePaginatedPayments(),
    allPayments: observePaymentsByBusiness(),
}));

export default enhance(TransactionList);
