import {createContext, useContext, useState} from 'react';
import Cookies from 'js-cookie';
import {findUserByID, observePOCount} from '../../pos-core/database/helpers';
import {aggregatePermission} from '../../constants';
import {database} from '../../pos-core/database';
import {postUserLogout, Sync} from '../../api';

export const AuthContext = createContext({
    user: null,
    renderToast: null,
    business: null,
    account: null,
    role: null,
    userRole: null,
    userBusiness: null,
    counter: null,
    access: null,
    deviceConfiguration: null,
    handleLoginInDB: () => {},
    handleLogoutInDB: () => {},
    handleIncrementCounter: () => {},
    handleCreateOrder: () => {},
    handleCreatePurchaseOrder: () => {},
    setUser: () => {},
    setRenderToast: () => {},
    setPersistData: () => {},
    setBusiness: () => {},
    setRole: () => {},
    setUserRole: () => {},
    setAccess: () => {},
    setCounter: () => {},
    setDeviceConfiguration: () => {},
    handleKdsLogin: () => {},
});

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const [account, setAccount] = useState(null);
    const [renderToast, setRenderToast] = useState(null);
    const [business, setBusiness] = useState(null);
    const [role, setRole] = useState(null);
    const [userBusiness, setUserBusiness] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [access, setAccess] = useState(null);
    const [counter, setCounter] = useState(null);
    const [persistData, setPersistData] = useState(null);
    const [deviceConfiguration, setDeviceConfiguration] = useState(null);

    const findBusinessHQ = async userBusinessDetails => {
        for (const item of userBusinessDetails) {
            const business = await item.business.fetch();
            if (business.is_hq) {
                return item; // Return the item if the business is HQ
            }
        }
        return undefined; // Return undefined if no HQ business is found
    };

    const getAllBusinessLocations = async user => {
        let temp = [];
        const userBusinessDetails = await user.user_business.fetch();
        for (const item of userBusinessDetails) {
            const business = await item.business.fetch();
            temp.push(business);
        }
        return temp;
    };

    const handleLogin = (sessionID, sessionCounter) => {
        return new Promise(async (resolve, reject) => {
            try {
                let DBuser = await findUserByID(sessionID);

                if (!DBuser.is_active) await DBuser.activateUser();

                let userBusinessDetails = await DBuser.user_business.fetch();
                const userBusinessess = await getAllBusinessLocations(DBuser);
                setUserBusiness(userBusinessess);

                if (userBusinessDetails.length) {
                    userBusinessDetails = userBusinessDetails[0];

                    let account = await DBuser.account.fetch();
                    let accoutRoles = await account.role.fetch();

                    const userRole = await userBusinessDetails?.role?.fetch();
                    const modules = await userRole.module.fetch();
                    // const permission = await aggregatePermission(userRole, 'dashboard');
                    let DBbusiness = await userBusinessDetails.business.fetch();

                    console.log('DB Business 01', DBbusiness);

                    if (!DBbusiness) {
                        await handleLogout();
                        reject();
                    } else {
                        if (!DBbusiness.is_active) await DBbusiness.activateBusiness();

                        let DBcounter = await handleCounter(DBuser, sessionCounter);
                        setUser(DBuser);
                        setBusiness(DBbusiness);
                        setAccount(account);
                        setUserRole(modules);
                        setAccount(account);
                        setRole(accoutRoles);
                        // setAccess(permission);
                        setCounter(DBcounter);

                        localStorage.setItem('session', sessionID);
                        localStorage.setItem('business_id', DBbusiness.id);
                        localStorage.setItem('is_hq', DBbusiness.is_hq);
                        localStorage.setItem('business_type', DBbusiness.type);

                        resolve({DBbusiness, userRole});
                    }
                } else {
                    await handleLogout();
                    reject();
                }
            } catch (err) {
                console.log('err in handleLogin', err);

                await handleLogout();
                reject();
            }
        });
    };

    const handleLogout = (forceLogout = false) => {
        return new Promise(async (resolve, reject) => {
            try {
                await Sync(user?.email);
                if (user) await user.deactivateUser();
                if (business) await business.deactivateBusiness();
                if (!forceLogout) {
                    await postUserLogout('');
                }

                setUser(null);
                setBusiness(null);
                setRole(null);
                setCounter(null);

                Cookies.remove('access_token');
                localStorage.clear();

                const account = await user?.account.fetch();
                await account?.deleteDatabase(database);
                resolve();
            } catch (err) {
                console.log('err in handleLogout', err);
                reject(err);
            }
        });
    };

    const handleDeviceCofiguration = newConfiguration => {
        setDeviceConfiguration(prev => ({
            ...prev,
            configuration: {
                ...newConfiguration,
            },
        }));
    };

    const hanldlePermission = (moduleName, allowedBusinessTypes) => {
        const permission = userRole?.find(mod => mod.name === moduleName);
        if (permission && allowedBusinessTypes.includes(business.type)) {
            return permission;
        } else {
            return null;
        }
    };

    const handleKdsLogin = deviceData => {
        setDeviceConfiguration(deviceData);
    };

    const handleCounter = (DBuser, sessionCounter) => {
        return new Promise(async (resolve, reject) => {
            try {
                let createdCounter;

                let counterList = await DBuser.counter.fetch();

                if (sessionCounter) {
                    let paramsForCounter = {
                        auth_session_count: sessionCounter,
                        pos_session_count: 0,
                        order_count: 0,
                    };

                    if (counterList.length) {
                        let counter = counterList[0];
                        createdCounter = await counter.updateDetails(paramsForCounter);
                    } else {
                        createdCounter = await DBuser.createCounter(paramsForCounter);
                    }
                } else {
                    createdCounter = counterList[0];
                }

                resolve(createdCounter);
            } catch (err) {
                console.log('err in handleCounter', err);
                reject(err);
            }
        });
    };

    const handleIncrementCounter = type => {
        return new Promise(async (resolve, reject) => {
            try {
                let updatedCounter;

                if (type == 'session') {
                    let updatedVal = counter.pos_session_count == null ? 0 : counter.pos_session_count + 1;
                    updatedCounter = await counter.updatePosSession(updatedVal);
                } else if (type == 'order') {
                    let updatedVal = counter.order_count == null ? 0 : counter.order_count + 1;
                    updatedCounter = await counter.updateOrderCount(updatedVal);
                }

                setCounter(updatedCounter);

                resolve(updatedCounter);
            } catch (err) {
                console.log('err in handleIncrementCounter', err);
                reject(err);
            }
        });
    };

    const handleCreateOrder = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let updatedCounter = await handleIncrementCounter('order');
                let order = await business.createOrder({
                    number: `${updatedCounter.auth_session_count}${updatedCounter.pos_session_count}${updatedCounter.order_count}`,
                    user,
                });
                resolve(order);
            } catch (err) {
                reject(err);
            }
        });
    };

    const handleCreatePurchaseOrder = poDetails => {
        return new Promise(async (resolve, reject) => {
            try {
                let count = await observePOCount();
                let order = await business.createPO(`PO # 00${count + 1}`, poDetails);

                resolve(order);
            } catch (err) {
                reject(err);
            }
        });
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                renderToast,
                persistData,
                business,
                account,
                role,
                userBusiness,
                userRole,
                counter,
                access,
                deviceConfiguration,
                account,
                handleDeviceCofiguration: handleDeviceCofiguration,
                hanldlePermission: hanldlePermission,
                handleKdsLogin: handleKdsLogin,
                handleLoginInDB: handleLogin,
                handleLogoutInDB: handleLogout,
                handleIncrementCounter,
                handleCreateOrder,
                handleCreatePurchaseOrder,
                setUser,
                setRenderToast,
                setPersistData,
                setBusiness,
                setRole,
                setUserRole,
                setAccess,
                setCounter,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
