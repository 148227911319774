import React, {useEffect, useState} from 'react';
import DashboardDataBox from '../DashboardDataBox';
import TabBox from '../TabBox';
import DonutChart from '../DonutChart/DonutChart';
import BarChart from '../BarChart/BarChart';
import DashboardGraphNonIdeal from '../NonIdeal/DashboardGraphNonIdeal';
import {graph_non_ideal} from '../../../assets/images/image';

const Graphcard = ({type, data, title, tabValue, setTabVal, handleNavigate, ViewMoreBtn}) => {
    const [isNonIdeal, setIsNonIdeal] = useState(false);

    const handleChange = (event, newValue) => {
        setTabVal(newValue);
    };

    const handleNonIdeal = (data, title) => {
        let isNonIdeal = false;

        if (['sales_by_channel', 'sales_by_payment_method', 'sales_by_day', 'sales_by_hour'].includes(title)) {
            isNonIdeal = data?.values?.every(value => value === 0);
        } else if (title === 'sales_by_category') {
            isNonIdeal = data.length === 0;
        }

        return isNonIdeal;
    };

    useEffect(() => {
        const nonIdeal = handleNonIdeal(data, title);
        setIsNonIdeal(nonIdeal);
    }, [data, title]);

    return (
        <DashboardDataBox ViewMoreBtn={ViewMoreBtn} title={title} handleView={handleNavigate}>
            {data.tabs ? <TabBox tabValue={tabValue} tabs={['AM', 'PM']} handleChange={handleChange} /> : null}
            {isNonIdeal ? (
                <DashboardGraphNonIdeal
                    data={{
                        title: 'youve_not_done_any_transactions_yet',
                        image: graph_non_ideal,
                    }}
                />
            ) : Object.keys(data).length > 0 ? (
                type === 'donut' ? (
                    <DonutChart data={data} />
                ) : (
                    <BarChart data={data} />
                )
            ) : null}
        </DashboardDataBox>
    );
};

export default Graphcard;
