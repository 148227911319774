import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import usePermission from '../../../../../../hooks/usePermission';
import {DashboardHeader, Graphcard, KeyMetrics, NonIdealScreen, TopSellingItems} from '../../../../../common';
import PaymentOnline from './PaymentOnline';
import PaymentReport from './PaymentReport';
import PendingPayment from '../../../../../common/PendingPayment';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import {payment_nonideal} from '../../../../../../assets/images/image';
import {paymentMockdata} from '../../../../../../constants';

const PaymentOverview = ({dashboardData, selectedDateRange, setSelectedDateRange, selectedLocation, setSelectedLocation}) => {
    const [viewDataModal, setViewDataModal] = useState(false);
    const [dropDownAction, setDropDownAction] = useState({value: {name: 'OverView'}});
    const isPosPermission = usePermission('pos');
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const userPermission = usePermission('category');

    const handleOnboarding = () => {
        // handleOnboarding
    };
    function extractScalarValues(obj) {
        const result = {
            return_matrix: obj.return_matrix,
            sales_matrix: obj.sales_matrix,
            transaction_matrix: obj.transaction_matrix,
        };

        return result;
    }

    const keyMetrics = extractScalarValues(dashboardData);

    const handleNavigatton = () => {};

    return (
        <>
            <Row>
                <Col md={12}>
                    <DashboardHeader
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        setSelectedDateRange={setSelectedDateRange}
                        selectedDateRange={selectedDateRange}
                        setDropDownAction={setDropDownAction}
                        dropDownAction={dropDownAction}
                        type="payment_dashboard"
                    />
                </Col>
            </Row>

            <Row className="pt-5">
                {false ? (
                    <>
                        {dropDownAction?.value?.name == 'online' ? (
                            <PaymentOnline />
                        ) : dropDownAction?.value?.name == 'reports' ? (
                            <PaymentReport />
                        ) : (
                            <>
                                <Col md={12}>
                                    <div className="saleKeyMetrics">
                                        <KeyMetrics type={'payment_dashborard'} isPosPermission={isPosPermission} metricsData={keyMetrics} title="payment_summary" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Graphcard type="bar" data={paymentMockdata} title={'successful_payments'} />
                                </Col>
                                <Col md={6}>
                                    <Graphcard type="bar" data={paymentMockdata} title={'average_transaction_value'} handleNavigate={handleNavigatton} ViewMoreBtn={true} />
                                </Col>
                                <Col md={6}>
                                    <Graphcard type="bar" data={paymentMockdata} title={'payouts'} handleNavigate={handleNavigatton} ViewMoreBtn={true} />
                                </Col>
                                <Col md={6}>
                                    <PendingPayment data={dashboardData?.top_selling_items} handleNavigate={handleNavigatton} />
                                </Col>
                            </>
                        )}
                    </>
                ) : (
                    <div className="onlineNonIdeal">
                        <NonIdealScreen
                            heading={I18n.effortless_payments_solutions}
                            subHeading={I18n.effortless_payments_descp_one}
                            nonIdealImage={payment_nonideal}
                            name="categoryIcon"
                            fill={theme.white}
                            secondaryCta={
                                userPermission?.canCrud
                                    ? {
                                          title: I18n.explore_payment_plans,
                                          action: handleOnboarding,
                                      }
                                    : null
                            }
                            isMultiStep={false}
                        />
                    </div>
                )}
            </Row>
        </>
    );
};

export default PaymentOverview;
