import {useNavigate} from 'react-router-dom';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import TableBody from '../../../../../common/TableContainer/TableBody';
import {Dropdown, MessageModal} from '../../../../../common';
import withObservables from '@nozbe/with-observables';
import {getCommaSeparatedNames} from '../../../../../../constants';
import {useState} from 'react';

const List = ({modifier, userPermission, product_modifier, modifier_option, filter, setRenderToast}) => {
    let navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [archiveModal, setArchiveModal] = useState(false);

    const {name, id} = modifier;

    const handleAction = async type => {
        switch (type) {
            case 'archive':
                toggleArchiveModal();
                break;
            case 'edit':
                navigateToNextScreen();
                break;
        }
    };

    const tableAction = [
        {
            title: I18n.edit,
            action: () => handleAction('edit'),
        },

        {
            title: filter == I18n.all_modifiers ? I18n.archive : I18n.unarchive,
            action: () => handleAction('archive'),
        },
    ];

    const navigateToNextScreen = () => {
        if (!userPermission?.canCrud) return;
        navigate(`${id}/edit`);
    };

    const archiveModifier = async () => {
        let status = filter == I18n.all_modifiers ? true : false;
        let toastMessage = filter == I18n.all_modifiers ? I18n.modifier_archived_successfully : I18n.modifier_unarchived_successfully;
        const productModifier = await modifier.product_modifier.fetch();
        await modifier.archiveModifier(status);
        await Promise.all(productModifier.map(elem => elem.updateProductModifierStatus(status)));
        setRenderToast(toastMessage);
        setArchiveModal(false);
    };

    const toggleArchiveModal = () => {
        setArchiveModal(x => !x);
    };
    return (
        <>
            <TableBody className={`fourColumn cursorPointer`} onClick={navigateToNextScreen} style={{borderColor: theme.inputBorder}}>
                <div className="checkboxCol BoxWidth">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {name}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {product_modifier?.length}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {getCommaSeparatedNames(modifier_option || [])}
                    </p>
                </div>

                {userPermission?.canCrud && (
                    <div
                        className="BoxWidth justifyCenter"
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div className="listActionBox">
                            <Dropdown
                                dropDown={{
                                    name: 'threeDotIcon',
                                    fill: theme.barclaysBlue,
                                    width: 18,
                                    height: 4,
                                    viewBox: '0 0 18 4',
                                    option: tableAction,
                                }}
                            />
                        </div>
                    </div>
                )}
            </TableBody>

            <MessageModal
                setToggle={setArchiveModal}
                toggle={archiveModal}
                className="messageModal"
                title={filter == I18n.all_modifiers ? I18n.archive_modifier : I18n.unarchive_modifier}
                description={
                    filter == I18n.all_modifiers ? I18n.are_you_sure_you_want_to_archive_the_selected_modifier : I18n.are_you_sure_you_want_to_unarchive_the_selected_modifier
                }
                secondaryCta={{
                    title: I18n.no,
                    action: toggleArchiveModal,
                }}
                primaryCta={{
                    title: I18n.yes,
                    action: () => {
                        archiveModifier();
                    },
                    isDelete: true,
                }}
            />
        </>
    );
};

const enhance = withObservables(['modifier'], ({modifier}) => ({
    product_modifier: modifier.product_modifier.observe(),
    modifier_option: modifier.modifier_option.observe(),
}));

export default enhance(List);
