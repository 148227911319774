import React from 'react'
import { useLanguageContext } from '../../../context'

const DashboardListNonIdeal = ({ title }) => {
    const {I18n} = useLanguageContext();
  return (
    <div className='listBoxNonIdeal'>
        <div className='listBoxInneNonIdeal'>
            <div className='listLeftSide'>
                <p className='marBot5'></p>
                <p className='marBot0'></p>
            </div>
            <div className='listRightSide'>
                <p className='marBot0'></p>
            </div>
        </div>
        <div className='listBoxInneNonIdeal'>
            <div className='listLeftSide'>
                <p className='marBot5'></p>
                <p className='marBot0'></p>
            </div>
            <div className='listRightSide'>
                <p className='marBot0'></p>
            </div>
        </div>

        <div className='listBoxInneNonIdeal'>
            <div className='listLeftSide'>
                <p className='marBot5'></p>
                <p className='marBot0'></p>
            </div>
            <div className='listRightSide'>
                <p className='marBot0'></p>
            </div>
        </div>

        <div className='listBoxInneNonIdeal'>
            <div className='listLeftSide'>
                <p className='marBot5'></p>
                <p className='marBot0'></p>
            </div>
            <div className='listRightSide'>
                <p className='marBot0'></p>
            </div>
        </div>
        <p className='fontWeight400 fontSize14 nonIdealText marBot40 width45 textLeft marBot0 marTop10'>
            {I18n[title]}
        </p>
    </div>
  )
}

export default DashboardListNonIdeal
