import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {Menu, PosPin, KdsPin} from '../components/common';
import {
    BusinessDetails,
    CreateCustomer,
    CreateDicounts,
    CreateItem,
    CreateOption,
    CreateOrder,
    CreateSession,
    CreateTeam,
    EditTeamMember,
    CreateSalesTax,
    CreatePurchaseTax,
    CustomerList,
    DiscountList,
    Home,
    ImportItems,
    ItemList,
    Login,
    Onboarding,
    OptionList,
    OrderPayment,
    SignUp,
    TeamList,
    SendEmail,
    SalesTaxList,
    OrderHistoryList,
    PurchaseOrderList,
    CreatePurchaseOrder,
    PurchaseOrderDetails,
    EditCustomer,
    EditSalesTax,
    EditPurchaseTax,
    EditItem,
    EditDiscount,
    OrderDetails,
    SendPurchaseorder,
    SupplierList,
    CustomerDetailScreen,
    ReportList,
    InvoiceList,
    AppSettings,
    Location,
    Dashboard,
    KitchenDisplay,
    KdsLogin,
    DisplaySetting,
    TimerSetting,
    WaiterList,
    CreateWaiter,
    EditWaiter,
    CreateCategories,
    CategoriesList,
    EditCategories,
    CompanyList,
    EditCompany,
    CreateCompany,
    ForgotPassword,
    PaymentTaxList,
    CreateRider,
    RiderList,
    EditRider,
    DealList,
    CreateDeal,
    EditDeal,
} from '../components/screens';
import SecondaryMenu from '../components/common/SecondaryMenu';
import KitchenDisplayOrder from '../components/screens/Authenticated/KItchenDisplayOrder';
import PosCockpit from '../components/screens/Authenticated/Restaurant/PosCockpit';
import FloorPlans from '../components/screens/Authenticated/Restaurant/FloorPlans';
import CreateFloorPlan from '../components/screens/Authenticated/Restaurant/CreateFloorPlan';
import EditFloorPlan from '../components/screens/Authenticated/Restaurant/EditFloorPlan';
import ModifierList from '../components/screens/Authenticated/Restaurant/Modifiers/ModifierList';
import CreateModifier from '../components/screens/Authenticated/Restaurant/Modifiers/CreateModifier';
import RestaurantOrder from '../components/screens/Authenticated/POS/CreateOrder/RestaurantOrder';
import Transactions from '../components/screens/Authenticated/Payments/Transactions/TransactionList';
import TransactionDetail from '../components/screens/Authenticated/Payments/Transactions/TransactionDetail';
import WalletList from '../components/screens/Authenticated/Payments/Wallet/WalletList';
import CreateLocation from '../components/screens/Authenticated/Setting/Location/CreateLocation';
import EditLocation from '../components/screens/Authenticated/Setting/Location/EditLocation';
import Unauthorized from '../components/screens/FallbackScreens/Unauthorized';
import ProtectedRoute from './ProtectedRoute';
import {useEffect, useRef} from 'react';
import {useMenuContext} from '../context';
import EditModifier from '../components/screens/Authenticated/Restaurant/Modifiers/EditModifier';
import {RESTAURANT, RETAIL} from '../constants';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Onbaording */}
                <Route path="login" element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="kds-login" element={<KdsLogin />} />

                <Route path="register" element={<SignUp />} />
                <Route path="unauthorized" element={<Unauthorized />} />

                {/* KDS Authenticated Routes */}
                <Route element={<SecondaryMenu />}>
                    <Route element={<KdsPin />}>
                        <Route path="kitchendisplay" element={<KitchenDisplayOrder />} />
                        <Route path="displaysetting" element={<DisplaySetting />} />
                        <Route path="timersetting" element={<TimerSetting />} />
                    </Route>
                </Route>

                {/* Authenticated */}
                <Route element={<Menu />}>
                    <Route
                        path="dashboard"
                        element={
                            <ProtectedRoute permissionName="dashboard" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="pos-cockpit"
                        element={
                            <ProtectedRoute permissionName="pos-cockpit" allowedBusinessTypes={[RESTAURANT]}>
                                <PosCockpit />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="floor-plans"
                        element={
                            <ProtectedRoute permissionName="floor-plans" allowedBusinessTypes={[RESTAURANT]}>
                                <FloorPlans />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="create-floor-plan"
                        element={
                            <ProtectedRoute permissionName="floor-plans" allowedBusinessTypes={[RESTAURANT]}>
                                <CreateFloorPlan />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="create-floor-plan/:id/edit"
                        element={
                            <ProtectedRoute permissionName="floor-plans" allowedBusinessTypes={[RESTAURANT]}>
                                <EditFloorPlan />
                            </ProtectedRoute>
                        }
                    />

                    <Route path="business-details" element={<BusinessDetails />} />
                    <Route path="onboarding" element={<Onboarding />} />
                    <Route path="session" element={<CreateSession />} />

                    <Route element={<PosPin />}>
                        <Route
                            path="/"
                            element={
                                localStorage.getItem('business_type') === RESTAURANT ? (
                                    <ProtectedRoute permissionName="pos-cockpit" allowedBusinessTypes={[RESTAURANT]}>
                                        <PosCockpit />
                                    </ProtectedRoute>
                                ) : (
                                    <ProtectedRoute permissionName="pos" allowedBusinessTypes={[RETAIL]}>
                                        <CreateOrder />
                                    </ProtectedRoute>
                                )
                            }
                        />

                        <Route
                            path="pos"
                            element={
                                <ProtectedRoute permissionName="pos" allowedBusinessTypes={[RETAIL]}>
                                    <CreateOrder />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="restaurant-pos"
                            element={
                                <ProtectedRoute permissionName="restaurant-pos" allowedBusinessTypes={[RESTAURANT]}>
                                    <RestaurantOrder />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                    <Route
                        path="pos/billing"
                        element={
                            <ProtectedRoute
                                permissionName={localStorage.getItem('business_type') === RESTAURANT ? 'restaurant-pos' : 'pos'}
                                allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <OrderPayment />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="waiters"
                        element={
                            <ProtectedRoute permissionName="waiters" allowedBusinessTypes={[RESTAURANT]}>
                                <WaiterList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="waiters/create"
                        element={
                            <ProtectedRoute permissionName="waiters" allowedBusinessTypes={[RESTAURANT]}>
                                <CreateWaiter />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="waiters/:id/edit"
                        element={
                            <ProtectedRoute permissionName="waiters" allowedBusinessTypes={[RESTAURANT]}>
                                <EditWaiter />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="riders"
                        element={
                            <ProtectedRoute permissionName="riders" allowedBusinessTypes={[RESTAURANT]}>
                                <RiderList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="riders/create"
                        element={
                            <ProtectedRoute permissionName="riders" allowedBusinessTypes={[RESTAURANT]}>
                                <CreateRider />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="riders/:id/edit"
                        element={
                            <ProtectedRoute permissionName="riders" allowedBusinessTypes={[RESTAURANT]}>
                                <EditRider />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="customers"
                        element={
                            <ProtectedRoute permissionName="customers" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CustomerList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="customers/:id/edit"
                        element={
                            <ProtectedRoute permissionName="customers" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <EditCustomer />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="customers/create"
                        element={
                            <ProtectedRoute permissionName="customers" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreateCustomer />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="customers/:id"
                        element={
                            <ProtectedRoute permissionName="customers" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CustomerDetailScreen />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="deals"
                        element={
                            <ProtectedRoute permissionName="deals" allowedBusinessTypes={[RESTAURANT]}>
                                <DealList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="deals/create"
                        element={
                            <ProtectedRoute permissionName="deals" allowedBusinessTypes={[RESTAURANT]}>
                                <CreateDeal />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="deals/:id/edit"
                        element={
                            <ProtectedRoute permissionName="deals" allowedBusinessTypes={[RESTAURANT]}>
                                <EditDeal />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="items"
                        element={
                            <ProtectedRoute permissionName="items" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <ItemList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="items/:id/edit"
                        element={
                            <ProtectedRoute permissionName="items" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <EditItem />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="items/create"
                        element={
                            <ProtectedRoute permissionName="items" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreateItem />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="modifiers"
                        element={
                            <ProtectedRoute permissionName="modifiers" allowedBusinessTypes={[RESTAURANT]}>
                                <ModifierList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="modifiers/create"
                        element={
                            <ProtectedRoute permissionName="modifiers" allowedBusinessTypes={[RESTAURANT]}>
                                <CreateModifier />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="modifiers/:id/edit"
                        element={
                            <ProtectedRoute permissionName="modifiers" allowedBusinessTypes={[RESTAURANT]}>
                                <EditModifier />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="option/create"
                        element={
                            <ProtectedRoute permissionName="option" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreateOption />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="option"
                        element={
                            <ProtectedRoute permissionName="option" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <OptionList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="team"
                        element={
                            <ProtectedRoute permissionName="team" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <TeamList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="team/create"
                        element={
                            <ProtectedRoute permissionName="team" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreateTeam />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="team/send-email"
                        element={
                            <ProtectedRoute permissionName="team" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <SendEmail />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="team/:id/edit"
                        element={
                            <ProtectedRoute permissionName="team" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <EditTeamMember />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="discounts"
                        element={
                            <ProtectedRoute permissionName="discounts" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <DiscountList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="discounts/:id/edit"
                        element={
                            <ProtectedRoute permissionName="discounts" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <EditDiscount />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="discounts/create"
                        element={
                            <ProtectedRoute permissionName="discounts" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreateDicounts />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="tax"
                        element={
                            <ProtectedRoute permissionName="tax" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <SalesTaxList />
                            </ProtectedRoute>
                        }
                    />
                    {/* <Route
                        path="payment_method_tax"
                        element={
                            <ProtectedRoute permissionName="payments">
                                <PaymentTaxList />
                            </ProtectedRoute>
                        }
                    /> */}

                    <Route
                        path="tax/sales/create"
                        element={
                            <ProtectedRoute permissionName="tax" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreateSalesTax />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="tax/purchase/create"
                        element={
                            <ProtectedRoute permissionName="tax" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreatePurchaseTax />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="tax/sales/:id/edit"
                        element={
                            <ProtectedRoute permissionName="tax" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <EditSalesTax />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="tax/purchase/:id/edit"
                        element={
                            <ProtectedRoute permissionName="tax" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <EditPurchaseTax />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="category/create"
                        element={
                            <ProtectedRoute permissionName="category" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreateCategories />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="category"
                        element={
                            <ProtectedRoute permissionName="category" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CategoriesList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="category/:id/edit"
                        element={
                            <ProtectedRoute permissionName="category" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <EditCategories />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="orders"
                        element={
                            <ProtectedRoute permissionName="orders" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <OrderHistoryList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="orders/:id"
                        element={
                            <ProtectedRoute permissionName="orders" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <OrderDetails />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="purchaseorder"
                        element={
                            <ProtectedRoute permissionName="purchaseorder" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <PurchaseOrderList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="purchaseorder/create"
                        element={
                            <ProtectedRoute permissionName="purchaseorder" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreatePurchaseOrder />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="purchaseorder/:id"
                        element={
                            <ProtectedRoute permissionName="purchaseorder" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <PurchaseOrderDetails />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="purchaseorder/share"
                        element={
                            <ProtectedRoute permissionName="purchaseorder" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <SendPurchaseorder />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="suppliers"
                        element={
                            <ProtectedRoute permissionName="suppliers" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <SupplierList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="report"
                        element={
                            <ProtectedRoute permissionName="report" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <ReportList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="invoice"
                        element={
                            <ProtectedRoute permissionName="report" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <InvoiceList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="setting"
                        element={
                            <ProtectedRoute permissionName="setting" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <AppSettings />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="location"
                        element={
                            <ProtectedRoute permissionName="location" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <Location />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="location/create"
                        element={
                            <ProtectedRoute permissionName="location" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreateLocation />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="location/:id/edit"
                        element={
                            <ProtectedRoute permissionName="location" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <EditLocation />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="device"
                        element={
                            <ProtectedRoute permissionName="device" allowedBusinessTypes={[RESTAURANT]}>
                                <KitchenDisplay />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="payments-transactions"
                        element={
                            <ProtectedRoute permissionName="payments" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <Transactions />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="payments-transactions/:id"
                        element={
                            <ProtectedRoute permissionName="payments" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <TransactionDetail />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="payments-wallet"
                        element={
                            <ProtectedRoute permissionName="payments" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <WalletList />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="company"
                        element={
                            <ProtectedRoute permissionName="company" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CompanyList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="company/:id/edit"
                        element={
                            <ProtectedRoute permissionName="company" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <EditCompany />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="company/create"
                        element={
                            <ProtectedRoute permissionName="company" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <CreateCompany />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="vendor"
                        element={
                            <ProtectedRoute permissionName="vendor" allowedBusinessTypes={[RETAIL, RESTAURANT]}>
                                <Unauthorized />
                            </ProtectedRoute>
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
