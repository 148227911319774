import {useState} from 'react';
import {Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {postAccountRegister, Sync} from '../../../../api';
import {useAuthContext, useLanguageContext} from '../../../../context';
import {bulkCreateModules, bulkCreateRoles, findUserByID} from '../../../../pos-core/database/helpers';
import {CustomContainer, Loading} from '../../../common';
import Step1 from './Step1';
import Step2 from './Step2';
import Cookies from 'js-cookie';
import { uuid, BUSINESS_FLOORS} from '../../../../constants';
import Icon from '../../../../assets/icons';
import {bulkCreateDineInTables} from '../../../../pos-core/database/helpers/dine_in_table';
import {bulkCreateOptions} from '../../../../pos-core/database/helpers/setting_config';
import { SETTING_CONFIG_OPTIONS } from '../../../../pos-core/constants/utils';

const SignUp = () => {
    const navigate = useNavigate();

    const {handleLoginInDB} = useAuthContext();
    const {I18n} = useLanguageContext();

    const [step, setStep] = useState('1');
    const [userDetails, setUserDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const toggleStep = val => setStep(val);

    const handleNext = details => {
        setUserDetails(details);
        toggleStep('2');
    };

    const navigateToLogin = () => {
        navigate('/login');
    };
    const createRoles = async account => {
        const accountRoles = [{name: 'owner'}, {name: 'admin'}, {name: 'inventory-manager'}, {name: 'manager'}, {name: 'cashier'}];

        const createdRoles = await bulkCreateRoles(account, accountRoles);
        const createdModules = await bulkCreateModules(createdRoles, null);

        console.log('created modules', createdModules);

        return createdRoles;
    };

    const createSettings = async business => {
        return await bulkCreateOptions(SETTING_CONFIG_OPTIONS, business);
    };

    const createBusinessFloorPlan = business => {
        try {
            BUSINESS_FLOORS.map(async item => {
                const createdFloor = await business.createFloorPlan(item);
                const tableObj = [
                    {
                        id: uuid(),
                        name: 'Table 01',
                        seats: 4,
                        shape: 'Square',
                        height: 160,
                        width: 200,
                        xAxis: 20,
                        yAxis: 20,
                        xCoord: 20,
                        yCoord: 20,
                    },
                ];
                const createdTables = await bulkCreateDineInTables(tableObj, createdFloor);
            });
        } catch (error) {
            console.log('error', error);
        }
    };

    const createBusinessPaymentMethod = async account => {
        try {
            const paymentMethods = [
                {name: 'cash', placement: 1},
                {name: 'card', placement: 2},
                {name: 'wallet', placement: 3},
                {name: 'credit', placement: 4},
            ];
            await Promise.all(paymentMethods.map(item => account.createPaymentMethod(item)));
        } catch (error) {
            console.log('Error in createPaymentMethod:', error);
        }
    };

    const handleDone = details => {
        setIsLoading(true);

        let paramsForApi = {
            first_name: userDetails.firstName,
            last_name: userDetails.lastName,
            email: userDetails.email,
            phone_number: userDetails.phoneNumber,
            username: `${details.firstName} ${details.lastName}`,
            country: details?.country,
            password: details.password,
            pin: details.pin,
            created_at: new Date().getTime(),
            last_visited: new Date().getTime(),
            archive: false,
        };

        postAccountRegister(paramsForApi)
            .then(async res => {
                const {account_id, business_id, user_id, access_token, login_session} = res?.data;

                try {
                    Cookies.set('access_token', access_token, {expires: 365 * 20});

                    await Sync(paramsForApi.email);

                    let DBuser = await findUserByID(user_id);
                    const account = await DBuser.account.fetch();
                    let user_business = await DBuser.user_business.fetch();
                    user_business = user_business?.[0];
                    let DBbusiness = await user_business.business.fetch();

                    console.log('DBbusiness----> ', DBbusiness);
                    const roles = await createRoles(account);
                    const ownerRole = roles?.find(role => role.name == 'owner');

                    const userBusinessDetails = {
                        role: ownerRole,
                    };

                    await user_business.setUserBusinessRole(userBusinessDetails);
                    await createBusinessFloorPlan(DBbusiness);
                    await createBusinessPaymentMethod(account);
                    await createSettings(DBbusiness);

                    await handleLoginInDB(user_id, login_session);
                    setIsLoading(false);
                    navigate('/business-details');
                } catch (err) {
                    console.log('Something went wrong', err);
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log('Something went wrong', err);
                setIsLoading(false);
            });
    };

    return (
        <>
            <CustomContainer className="signupCont">
                <div className="logoBox">
                    <Icon name={'oscarLogo'} fill={'white'} viewBox={'0 0 115 110'} width={'55'} height={'50'} />
                </div>

                <Row className="justify-content-md-center">
                    {step == '1' ? <Step1 handleSave={handleNext} navigateToLogin={navigateToLogin} /> : <Step2 toggleStep={toggleStep} handleSave={handleDone} />}
                </Row>
            </CustomContainer>

            {isLoading && <Loading heading={I18n.account_created} subHeading={I18n.few_more_questions} />}
        </>
    );
};

export default SignUp;
