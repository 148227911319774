import {RESTAURANT, RETAIL} from '../../../constants';

export const SUB_NAV_LIST = {
    payments: [
        {name: 'Wallet', route: 'payments-wallet', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {name: 'Transactions', route: 'payments-transactions', allowedBusinessTypes: [RETAIL, RESTAURANT]},
    ],
    suppliers: [
        // {name: 'Vendor', route: 'vendor', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {name: 'Company', route: 'company', allowedBusinessTypes: [RETAIL, RESTAURANT]},
    ],
    itemLibrary: [
        {name: 'Items', route: 'items', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {name: 'Categories', route: 'category', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {name: 'Modifiers', route: 'modifiers', allowedBusinessTypes: [RESTAURANT]},
        {name: 'Deals', route: 'deals', allowedBusinessTypes: [RESTAURANT]},
    ],
    teams: [
        {
            name: 'Team member',
            route: 'team',
            allowedBusinessTypes: [RETAIL, RESTAURANT],
        },
        {
            name: 'Waiters',
            route: 'waiters',
            allowedBusinessTypes: [RESTAURANT],
        },
        {
            name: 'Riders',
            route: 'riders',
            allowedBusinessTypes: [RESTAURANT],
        },
    ],

    taxes: [
        {name: 'tax', route: 'tax', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        // {name: 'payment method tax', route: 'payment_method_tax'},
    ],
};
