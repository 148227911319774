import {useLanguageContext, useThemeContext} from '../../../../../context';
import {Button} from '../../../../common';
import Icon from '../../../../../assets/icons';
import {formatNum} from '../../../../../constants';

const CardMethod = ({givenPosDiscount, paymentType, total, change, handleDone, order, orderLines, setShowMessage, customer, pendingPayment, isLastPayment, setSuccessModal}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const handlePayment = async () => {
        if (order?.type === 'delivery' && !customer) {
            let message = 'Customer is needed for delivery order';
            setShowMessage({
                visible: true,
                message: message,
            });
            return;
        } else if (paymentType?.name === 'credit' && !customer) {
            let message = 'Add customer if paying with credit flow';
            setShowMessage({
                visible: true,
                message: message,
            });
            return;
        } else {
            if (pendingPayment) {
                const paymentParams = {
                    payment_method: paymentType?.name,
                    card_number: '4242 4242 4242 4242',
                    status: 'complete',
                    received_amount: Number(total),
                    change: 0,
                    completed_at: new Date().getTime(),
                };

                const resp = await pendingPayment.updatePayment(paymentParams);
                console.log('response', resp);

                if (isLastPayment) {
                    handleDone('', true);
                } else {
                    setSuccessModal({
                        total,
                        change: 0,
                        method: paymentType?.name,
                    });
                }
            } else {
                handleDone(paymentType?.name, false);
            }
        }
    };

    return (
        <div className="cardContainer">
            <div className="billCardmount">
                <p className="marBot20 marTop10">
                    <Icon name="cardPaymentIcon" fill={theme.white} width={'151'} height={'140'} viewBox={'0 0 151 140'} />
                </p>
                <p className="marBot20 fontSize24 fontWeight400" style={{color: theme.white}}>
                    {I18n.swipe_your_card}
                </p>
                <p className="marBot0 fontSize24 fontWeight400" style={{color: theme.white}}>
                    {I18n.bill_amount}
                </p>
                <p className="marBot0 fontSize80 fontWeight600" style={{color: theme.white}}>
                    <span>{"Rs. "}</span>
                    <span>{formatNum(total)}</span>
                </p>
            </div>

            <div className="billScreenChangeButton">
                <Button
                    backgroundColor={theme.brightGreen}
                    txtColor={theme.white}
                    className={`width60 fontWeight600 fontSize18 cursorPointer`}
                    title={I18n.record_payment}
                    disabled={orderLines?.length ? false : true}
                    handleClick={handlePayment}
                />
            </div>
        </div>
    );
};

export default CardMethod;
