import {useCallback, useEffect, useState} from 'react';
import BarcodeReader from 'react-barcode-reader';
import {Container, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {of, switchMap} from 'rxjs';
import {DEVICE_HEIGHT, formatSingleDigitNum, generateRandomNumbers, handleUpdateProductQty, INITIAL_PAGE, ITEMS_PER_PAGE, RETAIL} from '../../../../../constants';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useOrderCalculation, useSessionUser} from '../../../../../hooks';
import useBarcode from '../../../../../hooks/useBarcode';
import useFocus from '../../../../../hooks/useFocus/index.js';
import useShortcut from '../../../../../hooks/useShortcut';
import NonIdeal from './NonIdeal.js';
import withObservables from '@nozbe/with-observables';
import {
    observeActiveProducts,
    observeActiveCategories,
    observeActiveOrder,
    bulkDeleteOrderLines,
    observeHeldOrderCount,
    queryItems,
    queryCategories,
} from '../../../../../pos-core/database/helpers';
import {BillAmountBox, Breadcrums, Button, HoldOrderModal, LeftPane, NonIdealScreen, PosDiscountModal, RightPane, SearchBox, TabBox, ToastBox} from '../../../../common';
import {ItemDiscountsModal, NewPosHeader, PosHeaderButton, SelectedCustomerModal, Tooltip} from '../../../../common/V2';
import LoyaltyPointsModal from '../../../../common/V2/LoyaltyPointsModal';
import CategoryList from './CategoryList';
import ProductList from './ProductList';
import OrderItem from './OrderLine';

const CreateOrder = ({activeProducts, activeCategories, orders, orderLines, customer, discount, sales_tax}) => {
    let order = orders[0];

    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, handleCreateOrder} = useAuthContext();
    const {localUser} = useSessionUser();
    const {textBoxRef, handleFocus} = useFocus(true);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [search, setSearch] = useState('');
    const [hasMoreItem, setHasMoreItem] = useState(true);
    const [hasMoreCategory, setHasMoreCategory] = useState(true);

    const {handleScan, handleError, barcode} = useBarcode(products, handleAddBarcodeSearchedProduct);
    useShortcut(
        {
            keys: [
                {key: 'shift+o', action: handleOpenItem},
                {key: 'shift+enter', action: handlePayment},
                {key: 'shift+s', action: handleFocus},
                {key: 'shift+n', action: redirectToItemScreen},
                {key: 'esc', action: closeModal},
            ],
        },
        orderLines,
    );

    const {subTotal, total, tax, giveItemLevelDiscount, givenPosDiscount, redeemAmount} = useOrderCalculation({order, orderLines, discount, sales_tax});
    const [tabValue, setTabVal] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectCustomer, setSelectCustomer] = useState(false);
    const [holdOrderModal, setHoldOrderModal] = useState(false);
    const [holdModal, setHoldModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [heldOrderCount, setHeldOrderCount] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [posDiscountModalToggle, setPosDiscountModalToggle] = useState(false);
    const [posDiscount, setPosDiscount] = useState(discount?.name || '');
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });
    const [orderDiscountModal, setOrderDiscountModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [tooltiptoggle, setTooltiptoggle] = useState(false);
    const [clearItemTooltiptoggle, setClearItemTooltiptoggle] = useState(false);
    const [redeemPoints, setRedeemPoints] = useState(null);
    const [isFetching, setIsFetching] = useState(true);

    const handleChange = useCallback((event, newValue) => {
        if (newValue === 0) {
            setCategories([]);
            setHasMoreCategory(true);
        }
        if (newValue === 1) {
            setProducts([]);
            setHasMoreItem(true);
        }
        setSearch('');
        setSelectedCategory(null);
        setTabVal(newValue);
        setIsFetching(true);
        setCurrentPage(INITIAL_PAGE);
    }, []);

    const resetRedeemPoints = async () => {
        setRedeemPoints(null);
        await handleRedeemPoints(0);
    };

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        resetRedeemPoints();
    }, [customer]);

    useEffect(() => {
        handleHeldOrderCount();
    }, [business]);

    const handleHeldOrderCount = async () => {
        let count = await observeHeldOrderCount(business?.id);
        setHeldOrderCount(count);
    };

    const toggleSelectCustomerModal = () => {
        setSelectCustomer(prev => !prev);
    };

    const handleCategory = val => {
        setSelectedCategory(val);
        setTabVal(1);

        setProducts([]);
        setHasMoreItem(true);
        setCurrentPage(INITIAL_PAGE);
        setIsFetching(true);
    };

    const handleProduct = (val, qty, type) => {
        handleUpdateProductQty(val, qty, type, orderLines, order, RETAIL);
    };

    function handleOpenItem() {
        let paramsForLine = {
            cost_price: 0,
            is_open: true,
            name: `Open item ${generateRandomNumbers()}`,
            quantity: 1,
            rate: 1,
            selling_price: 0,
            unit: 'Pieces',
        };

        order?.createOrderLine(paramsForLine);
    }

    function closeModal() {
        toggleSelectCustomerModal();
    }

    async function handleAddBarcodeSearchedProduct(barcodeSearchItem) {
        let prodTemplate = await barcodeSearchItem?.product_template.fetch();

        let paramsForItem = {
            product: barcodeSearchItem,
            name: barcodeSearchItem.name,
            selling_price: barcodeSearchItem.selling_price,
            cost_price: barcodeSearchItem.cost_price,
            is_open: false,
            quantity: 1,
            tax_name: barcodeSearchItem?.sales_tax?.name,
            tax_rate: barcodeSearchItem?.sales_tax?.rate,
            is_tax_inclusive: barcodeSearchItem?.sales_tax?.is_inclusive,
            rate: 1,
            unit: prodTemplate?.unit,
        };

        handleProduct(paramsForItem);
    }

    const handleClearAll = () => {
        if (orderLines?.length) bulkDeleteOrderLines(orderLines);
        handletoggleClearItem();
        setShowMessage({
            visible: true,
            message: I18n.order_clear_successfully,
        });
    };

    const onChange = async (event, key) => {
        let value = key || event?.target?.value || '';
        setSearch(value);

        const {data, totalCount} = selectedCategory
            ? await selectedCategory.getActiveProducts(INITIAL_PAGE, ITEMS_PER_PAGE, value.toLowerCase())
            : tabValue === 0
            ? await queryCategories('', value.toLowerCase(), INITIAL_PAGE, ITEMS_PER_PAGE)
            : await queryItems('', value.toLowerCase(), INITIAL_PAGE, ITEMS_PER_PAGE, false);

        if (data.length === 0) {
            tabValue === 0 ? setCategories(data) : setProducts(data);
        } else {
            if (tabValue === 0) {
                setCategories(data);
            } else {
                setProducts(data);
            }
        }

        tabValue === 0 ? setHasMoreCategory(totalCount > ITEMS_PER_PAGE) : setHasMoreItem(totalCount > ITEMS_PER_PAGE);
        setCurrentPage(INITIAL_PAGE);
        setIsFetching(false);
    };

    const handleSelectCustomer = async val => {
        const customerId = order?.customer?.id;
        let message = '';
        if (val == 'removeCustomer') {
            await order?.updateCustomer(null);
            message = I18n.remove_Customer;
        } else {
            message = customerId ? I18n.update_Customer : I18n.customer_added;
            await order?.updateCustomer(val);
        }
        toggleSelectCustomerModal();
        setShowMessage({
            visible: true,
            message: message,
        });
    };

    function handlePayment() {
        if (orderLines.length == 0) return;
        navigate('/pos/billing');
    }

    function redirectToItemScreen() {
        navigate('/items/create');
    }

    const handleHoldOder = () => {
        setHoldOrderModal(prev => !prev);
    };

    const handleOrderStatus = async status => {
        if (holdModal) setHoldModal(false);
        if (deleteModal) setDeleteModal(false);

        let paramsForOrder = {
            user: localUser,
            total,
        };

        await order.updateOrderCompletion(paramsForOrder);
        if (orders.length === 1) {
            await handleCreateOrder();
        }
        await order.updateOrderStatus(status);
        await handleHeldOrderCount();
    };

    const togglePosDiscount = () => {
        setPosDiscountModalToggle(prev => !prev);
    };

    const handlePosDiscount = async val => {
        if (val == 'remove_discount') {
            await order.updatePosDiscount(null);
            setPosDiscount('');
        } else {
            setPosDiscount(val?.name);
            await order.updatePosDiscount(val);
        }
        setPosDiscountModalToggle(false);
    };

    const handleItemDiscount = () => {
        setOrderDiscountModal(true);
    };

    const handleOrderDiscount = async (type, value) => {
        let discountAvailable = selectedItem?.discount;
        let discountObj = {
            value: Number(value),
            type,
        };
        await selectedItem?.updateItemDistcount(discountObj);
        setOrderDiscountModal(false);
        if (!discountAvailable) {
            setShowMessage({
                visible: true,
                message: I18n.item_level_discount_added,
            });
        }
    };

    const handleSelectedItem = item => {
        setSelectedItem(item);
    };

    const handletoggleTooltip = () => {
        setTooltiptoggle(prev => !prev);
    };

    const handletoggleClearItem = () => {
        setClearItemTooltiptoggle(prev => !prev);
    };

    const handleSavedOrder = () => {
        handletoggleTooltip();
        handleOrderStatus('hold');
        setPosDiscount('');
        setShowMessage({
            visible: true,
            message: I18n.order_saved,
        });
    };

    const handleCreateCustomer = () => {
        navigate(`/customers/create?id=${order.number}`);
    };
    const handleCreateItem = () => {
        navigate(`/items/create`);
    };

    const handleRedeem = () => {
        setRedeemPoints(true);
    };

    const handleRedeemPoints = async val => {
        await order.updateRedeemedPoints(Number(val));
    };

    useEffect(() => {
        if (isFetching) {
            fetchData(currentPage, selectedCategory);
        }
    }, [currentPage, isFetching, tabValue]);

    const fetchData = async (pageNum, selectedCategory) => {
        const {data, totalCount} = selectedCategory
            ? await selectedCategory.getActiveProducts(pageNum, ITEMS_PER_PAGE, search)
            : tabValue === 0
            ? await queryCategories('', search.toLowerCase(), pageNum, ITEMS_PER_PAGE)
            : await queryItems('', search.toLowerCase(), pageNum, ITEMS_PER_PAGE, false);

        if (data.length === 0) {
            tabValue === 0 ? setHasMoreCategory(false) : setHasMoreItem(false);
        } else {
            if (tabValue === 0) {
                setCategories(prevData => [...prevData, ...data]);
            } else {
                setProducts(prevData => [...prevData, ...data]);
            }
        }
        setIsFetching(false);
    };

    const fetchMoreData = () => {
        setIsFetching(true);
        setCurrentPage(prevPage => prevPage + 1);
    };

    const renderNonIdealScreen = () => {
        if (search) {
            return (
                <div className="posSearchNonidealBox">
                    <NonIdealScreen
                        heading={I18n.search}
                        backgroundColor={theme.topBarBG}
                        subHeading={I18n.we_couldnt_find_any_results_for_your_search}
                        name={'seacrhNotFoundIcon'}
                        fill={theme.white}
                        isMultiStep={false}
                        tablenonIdeal={true}
                    />
                </div>
            );
        } else {
            return (
                <div className="posNonidealBox">
                    <p className="marBot10 fontSize24 fontWeight400" style={{color: theme.white}}>
                        {I18n.items}
                    </p>
                    <p className="marBot10 fontSize16 fontWeight400" style={{color: theme.darkGrayTwo}}>
                        {I18n.create_categories_and_add_them_into_items}
                    </p>
                    <Button
                        type="secondary"
                        className={`fontWeight600 fontSize18 cursorPointer width30 marTop10`}
                        backgroundColor={theme.brightGreen}
                        title={I18n.create_item}
                        handleClick={handleCreateItem}
                    />
                </div>
            );
        }
    };

    return (
        <>
            <section className="mainAppCont" style={{backgroundColor: 'white'}}>
                <Container fluid className="noPadding">
                    <Row className="noMargin">
                        <LeftPane>
                            <div className="width100">
                                <SearchBox
                                    type="pos"
                                    placeholder={I18n.search_item_by_name_and_barcode}
                                    value={search}
                                    prefix={{
                                        name: 'search2Icon',
                                        fill: theme.white,
                                    }}
                                    suffix={{
                                        name: 'scanIcon',
                                        fill: theme.white,
                                    }}
                                    onChange={onChange}
                                    inputRef={textBoxRef}
                                />

                                <PosHeaderButton
                                    firstBtn={{
                                        title: I18n.saved_orders,
                                        action: handleHoldOder,
                                        value: heldOrderCount ? formatSingleDigitNum(heldOrderCount) : '',
                                        backgroundColor: heldOrderCount ? theme.barclaysBlue : theme.topBarBG,
                                        textColor: heldOrderCount ? theme.white : theme.barclaysBlue,
                                    }}
                                    secondBtn={{
                                        title: I18n.order_discount,
                                        action: togglePosDiscount,
                                        value: posDiscount,
                                        textColor: posDiscount ? theme.white : theme.barclaysBlue,
                                        backgroundColor: posDiscount ? theme.barclaysBlue : theme.topBarBG,
                                    }}
                                    thirdBtn={{
                                        title: I18n.open_item_name,
                                        action: handleOpenItem,
                                    }}
                                    fourBtn={{
                                        title: I18n.clear_all_items,
                                        action: handleClearAll,
                                        toggle: clearItemTooltiptoggle,
                                        handletoggleTooltip: handletoggleClearItem,
                                    }}
                                    orderLines={orderLines}
                                />
                                <TabBox tabValue={tabValue} tabs={[I18n.categories, I18n.items]} handleChange={handleChange} />
                                <Breadcrums tabValue={tabValue} selectedCategory={selectedCategory} handleCategory={handleCategory} />
                            </div>
                            <div
                                className="leftPaneMainBox"
                                style={{
                                    height: DEVICE_HEIGHT - 303,
                                }}>
                                <div className="leftPaneItemsBox" id="scrollableItemsWrapper">
                                    {tabValue === 0 ? (
                                        categories?.length > 0 ? (
                                            <CategoryList data={categories} handleCategory={handleCategory} fetchMoreData={fetchMoreData} hasMoreCategory={hasMoreCategory} />
                                        ) : (
                                            renderNonIdealScreen()
                                        )
                                    ) : products?.length > 0 ? (
                                        <ProductList data={products} handleProduct={handleProduct} fetchMoreData={fetchMoreData} hasMoreItem={hasMoreItem} />
                                    ) : (
                                        renderNonIdealScreen()
                                    )}
                                </div>

                                <div className="leftpaneBottom">
                                    <p className="fontSize12 marBot0 cursorPointer" style={{color: theme.white}}>
                                        <span onClick={() => handleOpenItem()}>{I18n.open_item}</span>
                                        <span className="posBottomSeperator height16"></span>
                                        <span onClick={() => redirectToItemScreen()}>{I18n.add_new_item}</span>
                                    </p>
                                </div>
                            </div>
                        </LeftPane>

                        <RightPane>
                            <NewPosHeader
                                title={{
                                    name: I18n.order_details,
                                }}
                                customer={customer}
                                order={order}
                                handleRedeem={handleRedeem}
                                rightAction={{
                                    title: customer?.name || I18n.add_customer,
                                    icon_name: customer ? 'profileTicketIcon' : 'add2Icon',
                                    action: toggleSelectCustomerModal,
                                }}
                            />
                            {orderLines?.length ? (
                                <div
                                    className="posrightSectionInner"
                                    style={{
                                        height: DEVICE_HEIGHT - 105,
                                    }}>
                                    <div className="cartItemList">
                                        {orderLines?.map((item, index) => (
                                            <OrderItem
                                                item={item}
                                                key={index}
                                                isOpenItem={item.is_open}
                                                handleProduct={handleProduct}
                                                handleSelectedItem={handleSelectedItem}
                                                handleItemDiscount={handleItemDiscount}
                                            />
                                        ))}
                                    </div>

                                    <div
                                        className="posPaymentContainer"
                                        style={{
                                            height: 'auto',
                                        }}>
                                        <div className="amountContainer">
                                            <BillAmountBox
                                                className={`width100`}
                                                subTotal={subTotal}
                                                total={total}
                                                tax={tax}
                                                giveItemLevelDiscount={giveItemLevelDiscount}
                                                givenPosDiscount={givenPosDiscount}
                                                redeemAmount={redeemAmount}
                                            />
                                        </div>

                                        <div className="posPaymentBtnContainer">
                                            <span>
                                                <Tooltip
                                                    titleName={I18n.new_order2}
                                                    desc={I18n.new_order_toolpit_desc}
                                                    yesCta={{action: handleSavedOrder, title: I18n.yes}}
                                                    noCta={{
                                                        action: handletoggleTooltip,
                                                        title: I18n.no,
                                                    }}
                                                    toggle={tooltiptoggle}
                                                    children={
                                                        <Button
                                                            type="primaryButton"
                                                            className={`fontSize18 cursorPointer width100`}
                                                            backgroundColor={theme.newposRightBg}
                                                            txtColor={theme.barclaysBlue}
                                                            title={I18n.new_order2}
                                                            handleClick={handletoggleTooltip}
                                                        />
                                                    }
                                                />
                                            </span>
                                            <span>
                                                <Button
                                                    type="secondary"
                                                    className={`fontSize18 cursorPointer width100`}
                                                    backgroundColor={theme.brightGreen}
                                                    txtColor={theme.white}
                                                    title={I18n.check_out}
                                                    handleClick={handlePayment}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <NonIdeal />
                            )}
                        </RightPane>
                        <div>
                            <BarcodeReader onError={handleError} onScan={handleScan} />
                        </div>
                        <ToastBox
                            className="addSuccessfullyToast"
                            ToastVisiable={showMessage.visible}
                            bodyPara={showMessage.message}
                            setShowMessage={setShowMessage}
                            showIcon={true}
                        />
                    </Row>
                </Container>
            </section>

            <SelectedCustomerModal
                setToggle={setSelectCustomer}
                toggle={selectCustomer}
                order={order}
                handleSelect={handleSelectCustomer}
                handleCreateCustomer={handleCreateCustomer}
            />

            <ItemDiscountsModal
                setToggle={setOrderDiscountModal}
                selectedItem={selectedItem}
                toggle={orderDiscountModal}
                handleSave={handleOrderDiscount}
                showDiscountType={true}
                title={I18n.item_level_discount}
            />
            <LoyaltyPointsModal
                visible={redeemPoints}
                setVisible={setRedeemPoints}
                handleRedeemPoints={handleRedeemPoints}
                setShowMessage={setShowMessage}
                customer={customer}
                order={order}
            />

            <HoldOrderModal
                setToggle={setHoldOrderModal}
                toggle={holdOrderModal}
                setShowMessage={setShowMessage}
                handlehandleSelect={handleHoldOder}
                handleHeldOrderCount={handleHeldOrderCount}
            />
            <PosDiscountModal setToggle={setPosDiscountModalToggle} toggle={posDiscountModalToggle} handleSelect={handlePosDiscount} order={order} screenName="creatOrder" />
        </>
    );
};

const enhance = withObservables([], () => ({
    activeProducts: observeActiveProducts(),
    activeCategories: observeActiveCategories(),
    orders: observeActiveOrder(),
    orderLines: observeActiveOrder().pipe(
        switchMap(order => (order?.length ? order[0]?.order_line.observeWithColumns(['quantity', 'archive', 'unit', 'discount', 'selling_price']) : of(null))),
    ),
    customer: observeActiveOrder().pipe(switchMap(order => (order?.length ? order[0]?.customer.observe() : of(null)))),
    discount: observeActiveOrder().pipe(switchMap(order => (order?.length ? order[0]?.discount.observe() : of(null)))),
    sales_tax: observeActiveOrder().pipe(switchMap(order => (order?.length ? order[0]?.sales_tax.observe() : of(null)))),
}));

export default enhance(CreateOrder);
