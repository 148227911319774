import React, {useEffect, useState} from 'react';
import {Container, Row} from 'react-bootstrap';
import {Loading, SalesHeader, TabBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {getDashboardAnalytics} from '../../../../../api';
import usePermission from '../../../../../hooks/usePermission';
import {dateFilterMapping, DEVICE_HEIGHT, formatDateWithTime, formateIdForApi} from '../../../../../constants';
import SalesOverview from './Sales';
import PaymentOverview from './Payment';

const NewDashboardComponent = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, userBusiness, role, account} = useAuthContext();
    const [tabValue, setTabVal] = useState(0);
    const userPermission = usePermission('dashboard');
    const [dashboardData, setDashboardData] = useState({});
    const [selectedDateRange, setSelectedDateRange] = useState({start_date: formatDateWithTime(new Date()), end_date: formatDateWithTime(new Date())});
    const [selectedLocation, setSelectedLocation] = useState({
        [business?.id]: {
            business_name: business?.location_name,
            selected: true,
        },
    });
    const [loading, setLoadig] = useState(false);
    const locationString = formateIdForApi(selectedLocation, business?.id);

    /**
     * Fetches and updates dashboard analytics data based on selected date range and location
     */
    useEffect(() => {
        setLoadig(true);
        const apiPayload = {
            start_date: selectedDateRange?.start_date,
            end_date: selectedDateRange?.end_date,
            date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
            // business_ids: role[0]?._raw?.name === 'dashboard-admin' ? null : locationString,
            business_ids: locationString,
        };
        getDashboardAnalytics(apiPayload)
            .then(resp => {
                if (resp) setDashboardData(resp);
                setLoadig(false);
            })
            .catch(err => {
                console.log('API call failed:', err);
            });
    }, [userPermission, selectedDateRange, selectedLocation]);

    /**
     * Handles a change event by updating the tab value.
     * @param {number} newValue - the new tab value
     * @return {void}
     */
    const handleChange = (event, newValue) => {
        setTabVal(newValue);
    };

    return Object.keys(dashboardData)?.length <= 0 ? (
        <Loading />
    ) : (
        <div
            className="dashboardBox padt20"
            style={{
                background: theme.topBarBG,
                height: DEVICE_HEIGHT - 40,
            }}>
            <Container fluid className="">
                <TabBox tabValue={tabValue} tabs={[I18n.sales, I18n.payment]} handleChange={handleChange} />
                {tabValue == 0 ? (
                    <SalesOverview
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        dashboardData={dashboardData}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        loading={loading}
                        setLoading={setLoadig}
                    />
                ) : (
                    <PaymentOverview
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        dashboardData={dashboardData}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                    />
                )}
            </Container>
        </div>
    );
};
export default NewDashboardComponent;
