import React from 'react';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../../assets/icons';
import {firstLetterCaptilize} from '../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../context';
import Button from '../../Button';
import Dropdown from '../../Dropdown';

const NewPosHeader = ({handleAction, backAction, rightAction, centerAction, leftAction, title, backBtnCta = false, showMoreIcon, customer, handleRedeem, order}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const navigate = useNavigate();

    return (
        <>
            <div className="posRightHeader width100">
                <p className="cursorPointer fontSize22 marBot0">
                    {backBtnCta ? (
                        <span onClick={backAction ? backAction : () => navigate(-1)}>
                            <Icon name="backArrowIcon" fill={theme.barclaysBlue} width={'18'} height={'18'} viewBox={'0 0 18 18'} />
                        </span>
                    ) : null}
                    {title && (
                        <>
                            <span style={{color: title.color || theme.white}} className="marLeft10 fontSize20 fontWeight400">
                                {firstLetterCaptilize(title.name) || ''}
                            </span>
                            {title.decs && (
                                <span style={{color: theme.darkGrayTwo}} className="marLeft10 fontSize14 fontWeight400">
                                    {firstLetterCaptilize(title.decs) || ''}
                                </span>
                            )}
                        </>
                    )}
                </p>

                {rightAction && (
                    <div className="flex verticalCenter">
                        {leftAction && (
                            <>
                                <p className="marBot0 fontSize14 marLeft5 cursorPointer" style={{color: leftAction.textColor}} onClick={() => leftAction.action()}>
                                    {leftAction.title}
                                </p>
                                <p className="topBarseperator  height20"></p>
                            </>
                        )}

                        {centerAction && (
                            <>
                                <p className="marBot0 fontSize14 marLeft5 cursorPointer" style={{color: centerAction.textColor}} onClick={() => centerAction.action()}>
                                    {centerAction.title}
                                </p>
                                <p className="topBarseperator  height20"></p>
                            </>
                        )}

                        {customer?.available_points && (
                            <>
                                <div className="flex verticalCenter cursorPointer">
                                    {order?.redeemed_points ? (
                                        <>
                                            <span style={{marginBottom: '8px'}}>
                                                <Icon name="tickCircleIcon" fill={theme.barclaysBlue} width={'18'} height={'18'} viewBox={'0 0 18 18'} />
                                            </span>
                                            <p className="marBot0 fontSize14 marLeft5" style={{color: theme.barclaysBlue}} onClick={() => handleRedeem()}>
                                                {`${order?.redeemed_points} points redeemed`}
                                            </p>
                                        </>
                                    ) : (
                                        <p className="marBot0 fontSize14 marLeft5" style={{color: theme.barclaysBlue}} onClick={() => handleRedeem()}>
                                            {`Redeem points`}
                                        </p>
                                    )}
                                    <p className="topBarseperator  height20"></p>
                                </div>
                            </>
                        )}

                        <Button
                            type="buttonTitleIcon"
                            handleClick={() => rightAction?.action()}
                            className={`cursorPointer addCustomerBtn`}
                            title={rightAction?.title}
                            backgroundColor={'transparent'}
                            txtColor={rightAction.txtColor || theme.barclaysBlue}
                            borderColor={theme.seperatorTwo}
                            icon_name={rightAction?.icon_name}
                            icon_fill={rightAction.icon_fill || theme.barclaysBlue}
                            icon_width={'18'}
                            icon_height={'18'}
                            icon_viewBox={'0 0 18 18'}
                        />
                        {showMoreIcon && (
                            <div className="posRightHeaderDropdown">
                                <Dropdown
                                    dropDown={{
                                        name: 'threeDotIcon',
                                        fill: theme.barclaysBlue,
                                        width: 18,
                                        height: 4,
                                        viewBox: '0 0 18 4',
                                        option: showMoreIcon.tableAction,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default NewPosHeader;
