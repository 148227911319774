const API_ENDPOINTS = {
    LOGIN: '/users/login/',
    LOGOUT: '/users/logout/',
    REGISTER: '/users/register/',
    CHECK_EMAIL: '/users/unique/',
    SYNC: '/sync/v1/sync/',
    CHECK_PHONE_NUMBER: '/users/uniquephone/',
    UPLOAD_IMAGE: '/sync/v1/upload/',
    UPDATE_PASSWORD: '/users/updatePassword/',
    GET_CATALOGS: 'products/uploadCatalog',
    CREATE_DEVICE: '/kds/createDevice/',
    UPDATE_PIN: '/kds/updatePin/',
    UPDATE_ODOO_CONFIG: '/kds/updateOdooConfig/',
    LOGIN_DEVICE: 'kds/loginDevice/',
    VALIDATE_PIN: 'kds/loginDevicePin/',
    GET_KDS_ORDER: '/kds/order/',
    UPDATE_KDS_ORDER: '/kds/orderUpdateDelete/',
    WEB_SOCKET_URL: 'wss://oscar-pos-api-stage.oscar.pk/ws/kitchen/',
    KITCHEN_NOTIFICATIONS: '/kds/kitchenNotifications/',
    UPDATE_DEVICE_CONFIG: '/kds/deviceConfigUpdate/',
    SEND_EMAIL: '/kds/emailSecretKey/',
    SEND_USER_CRED: '/users/sendEmail/',
    GET_COUNTRY: '/marketplace/all/countryProvinceCities/',
    SEND_OTP: '/users/SendOtp/',
    VERIFY_EMAIL: '/users/VerifyEmail/',
    FORGOT_PASSWORD: '/users/ForgetPassword/',
    DASHBOARD_ANALYTICS: '/analytics/',
    POST_BUSINEES_MARGIN: '/businessMargin/',
    GET_MARGIN_REPORT: '/businessMargin/ledger/',
    GET_MARGIN_ACTIVATION: '/businessMargin/activatedMarginDate/',
    GET_TOP_AND_LOW_SELLING_PRODUCTS: '/analytics/topLowSellingProducts/',
    GET_LOW_AND_OUT_OF_STOCK_PRODUCTS_And_EXORT_CSV: '/analytics/lowOutOfStockProducts/',
    GET_LOCTION_WISE_SALES_AND_EXPORT_CSV: '/analytics/locationWiseSellingView/',
    GET_TOP_AND_LOW_SELLING_PRODUCTS_EXPORT_CSV: '/analytics/exportTopLowSellingProducts/',
    GET_SALES_REPORT_ANALYTICS: '/analytics/salesReport',
};

export default API_ENDPOINTS;
