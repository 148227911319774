import {Autocomplete, InputAdornment, TextField} from '@mui/material';
import Icon from '../../../assets/icons';
import {useState} from 'react';
import {capitalizeFirstAlphabet} from '../../../constants';

const TextBox = ({inputType, maxLength, data, onClick, label, type, rows, inputRef, highlighter, setOptionAsLabel, variant, required, suffixIcon, ...rest}) => {
    // const [open, setOpen] = useState(false);
    // const handleFocus = () => {
    //     setOpen(true);
    // };
    // const handleBlur = () => {
    //     setOpen(false);
    // };

    const myHighlighter = (props, option) => {
        const {name, color} = option;
        return (
            <span {...props} style={{color: color}}>
                {name}
            </span>
        );
    };

    return inputType === 'select' ? (
        <Autocomplete
            ref={inputRef}
            // open={open}
            // onFocus={handleFocus}
            // onBlur={handleBlur}
            clearIcon={false}
            popupIcon={suffixIcon}
            blurOnSelect
            autoComplete
            includeInputInList
            options={data?.length ? data : []}
            getOptionLabel={option => setOptionAsLabel && capitalizeFirstAlphabet(option?.name || option)}
            label={label}
            type={type}
            renderOption={highlighter ? myHighlighter : null}
            renderInput={params => <TextField {...params} label={label} required={required} onClick={() => onClick && onClick(label)} variant="filled" inputRef={inputRef} />}
            {...rest}
        />
    ) : inputType === 'textarea' ? (
        <TextField
            id="filled-multiline-static"
            label={label}
            multiline
            rows={rows}
            required={required}
            // defaultValue="Default Value"
            variant="filled"
            {...rest}
        />
    ) : (
        <TextField
            InputProps={{
                endAdornment: suffixIcon,
            }}
            inputRef={inputRef}
            inputProps={{maxLength, inputMode: type}}
            autoComplete="off"
            variant={variant}
            label={label}
            type={type}
            required={required}
            {...rest}
        />
    );
};

export default TextBox;
